// ###### General variables ######

// colors
$main-theme-hover:              darken($main-theme, 15) !default;
$main-theme-complementary:      $main-theme-hover;
$btn-default:                   $main-theme;
$btn-default-hover:             $main-theme-hover;

// map colors (for marker groups)
$map-colors: ($main-theme, $main-theme-complementary, $main-theme-hover, $main-theme, $main-theme-complementary, $main-theme-hover,);

// cookiebanner colors
$cookiebanner-accept:  rgb(40, 173, 97);
$cookiebanner-decline: rgb(184, 50, 50);

// border radius null
$border-radiuses: ();

// Navigation adjustments
$nav-side-boxed:                false;
$nav-side-ver-padding:          8px !default;
$nav-side-right-padding:        20px !default;
$nav-side-left-padding:         2px !default;

$nav-mobile-position:           right;
$nav-mobile-side-content-move:  false;
$nav-mobile-show-box:           true;
$nav-header-ver-padding-xs-sm:  8px;
$nav-mobile-box-top:            4px;
$nav-setting-fixheader:         upscroll;

$nav-logo-max-height:           250px;


// ###### Imports ######

// import default common variables for Aachen
@import '../scss-themes/style-aachen-variables.scss';

// Import the Apollo default files
@import '../../../apollo-template/apollo-src/scss-themes/imports';

// Import the Bistuemer specific extensions
@import '../scss/imports-all';

// Import Aachen common styles
//@import '../scss-themes/style-aachen-commons.scss';
@import '../scss-themes/all-commons.scss';

// ###### overall adjustments ######
// headline in theme color
.headline * {
  color: $main-theme;
}

@import
'components/download',
'components/googlemap',
'components/header',
'components/footer',
'components/teaser',
'components/typography',
'components/direktorium',
'components/slider',
'components/cookiebanner';
