.privacy-policy-banner {
  .banner {
    background-color: rgba(37, 37, 37, 0.9);
    .title {
      font-size: 2rem;
    }
    .buttons {
      .btn {
        border-radius: 2px;
        font-size: 1.5rem;
        line-height: 1em;
        &-decline {
          background-color: $cookiebanner-decline;
          &:hover, &:focus {
            background-color: lighten($cookiebanner-decline, 10);
            color: #fff;
          }
        }
        &-accept {
          background-color: $cookiebanner-accept;
          &:hover, &:focus {
            background-color: lighten($cookiebanner-accept, 10);
            color: #fff;
          }
        }
      }
    }
    .message {
      font-size: 1.3rem;
      a {
        text-decoration: underline;
        font-weight: initial;
      }
    }
  }
}


.privacy-policy-banner--light {
  .banner {
    background-color: $grey-lighter;
    border-top: 3px solid $main-theme;
    * {
      color: $text-dark;
    }
    .title {
      font-size: 2rem;
    }
    .buttons {
      .btn {
        border-radius: 2px;
        font-size: 1.5rem;
        line-height: 1em;
        &-decline {
          background-color: $cookiebanner-decline;
          &:hover, &:focus {
            background-color: lighten($cookiebanner-decline, 10);
            color: #fff;
          }
        }
        &-accept {
          background-color: $cookiebanner-accept;
          &:hover, &:focus {
            background-color: lighten($cookiebanner-accept, 10);
            color: #fff;
          }
        }
      }
    }
    .message {
      font-size: 1.3rem;
      a {
        text-decoration: underline;
        font-weight: initial;
      }
    }
  }
}


#privacy-policy-toggle {
  .toggle-text {}
  .toggle-box {
    border: none;
    border-radius: 5px;
    .toggle-inner {
      &:before {
        background-color: $cookiebanner-accept;
        color: #fff;
      }
      &:after {
        background-color: $cookiebanner-decline;
        color: #fff;
      }
    }
    .toggle-slider {
      border-radius: 5px;
      border: none;
    }
  }
}
