.ap-header {
  .bis-top {
    .ap-linksequence-hf {
      margin-top: 5px;
      ul.links {
        li > a {
          font-size: $nav-meta-font-size;
        }
      }
    }
  }
  .bis-logo {
    .ap-image-section {
      .image-src-box {
      }
    }
  }
  .isfixed {
    .isfixed-hidden,
    .ap-slick-slider {
      display: none;
    }
  }
  .breadcrumbs {
    ul.breadcrumb {
      float: $nav-header-float !important;
    }
    @if($nav-header-float == 'left') {
      .breadcrumb > li:nth-of-type(1) {
        & > a {
          padding: $nav-header-padding;
          padding-right: initial;
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .ap-header {
    .bis-logogroup {
      display: flex;
      .bis-logo {
        .ap-image-section {
          .ap-image {
            .image-src-box {
              height: auto;
              padding-bottom: 0 !important;
              .image-inner-box {
                max-height: $nav-logo-max-height;
                width: 100%;
                position: relative;
              }
            }
          }
        }
      }
      .bis-site {
        position: relative;
        align-self: flex-end;
        margin-top: 50px;
        .sitename {
          text-align: right;
        }
      }
    }
  }
}
