.ap-slick-slider {
  .ap-slick-slide {
    &.custom .caption {
      &.center {
        strong,
        small {
          float: left;
          width: 100%;
        }
      }
    }
  }
}
