// Gottesdienstliste
.direktorium {
  $direktorium-text-color: #313131; //$text-dark;
  font-weight: 400;
  line-height: 1.5;
  // Grade
  // wichtige Feste oder Ereignisse
  .grad1,
  .grad2,
  .grad3,
  .grad4,
  .grad5 {
  }

  // i.d.R. Bezeichnungen für Tage
  .grad6,
  .grad9,
  .grad13 {
  }

  // i.d.R. Namen von Personen
  .grad7,
  .grad8,
  .grad10,
  .grad11,
  .grad12 {
  }

  // Ränge
  .rangg { // Nicht gebotener Gedenktag
    &, .dheader {
      font-size: 1.2rem;
      color: $direktorium-text-color;
    }
  }
  .rang,
  .rangG { // Gedenktag
    &, .dheader {
      font-size: 1.6rem;
      color: $direktorium-text-color;
    }
  }
  .rangF, // Fest
  .rangH { // Hochfest
    &, .dheader {
      font-size: 1.8rem;
      color: $direktorium-text-color;
    }
  }
  // Farben
  .farbeg,
  .farber,
  .farbev,
  .farbew {
    padding-left: 1.25rem;
    &:before {
      border: none;
      border-radius: 0;
      height: 1em;
      width: 5px;
      top: .25em;
    }
  }
  .farbeg:before { // Grün
    background-color: #01842d;
  }
  .farber:before { // Rot
    background-color: #a50202;
  }
  .farbev:before { // Violett
    background-color: #660099;
  }
  .farbew:before { // Weiß
    background-color: #fff;
    border: 1px solid #989fa9;
  }
}
.ap-list-group {
  .ser-head a {
    color: $main-theme;
  }
  .ser-loc a {
    color: $text-dark;
  }
}
