// map buttons matching marker color
.ap-google-map {
  .mapbuttons {
    .btn {
      @each $current-color in $map-colors {
        $i: index($map-colors, $current-color);
        $invert: length($map-colors) - ( $i - 1 );
        &:nth-of-type(#{$i+1}) {
          background-color: nth($map-colors, $invert);
        }
      }
    }
  }
}
