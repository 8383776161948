.ap-teaser {
  .teaser-visual {
  }
  .teaser-body {
    .teaser-title {
      font-size: $font-content-size * 1.2;
    }
    // .teaser-headline {
    //   &,
    //   & > span {
    //     font-weight: 400;
    //   }
    // }
  }
  .teaser-link {
    .teaser-btn {
    }
  }
}
