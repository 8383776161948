// headline in theme color
.headline * {
  color: $main-theme;
}

// external links
a {
  &.external {
    &:after {
      font-size: .6em;
      vertical-align: super;
      padding-left: .2em;
      display: inline-block;
    }
  }
}