// make download box in footer more compact
.area-foot .ap-download-list {
  .dl-type {
  }
  .dl-content {
    h3 {

    }
    .dl-file {
      display: none;
    }
  }
  .dl-info {
    .dl-size {

    }
    .dl-date {
      display: none;
    }
  }
  .dl-dl {
    display: none !important;
  }
}